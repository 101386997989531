import { css } from '@amedia/brick-tokens';

import type { ButtonStyleVariants } from './types';
export { getCssText } from '@amedia/brick-tokens';

export const componentStyle: () => string = css({
  '@property --b-avatar-size': `{
    syntax: "<length> | <percentage>";
    inherits: true;
    initial-value: 'auto';
  }`,
  '--_b-button-width': 'var(--b-button-width, auto)',
  width: 'var(--_b-button-width)',
});

export const buttonStyle: (variants: ButtonStyleVariants) => string = css({
  // using pseudoelement to set height for clickable area

  '&:before': {
    content: `''`,
    position: 'absolute',
    minHeight: '32px',
    minWidth: '32px',
    inset: '-2px',
  },
  '& span': {
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
  },
  position: 'relative',
  appearance: 'none',
  boxSizing: 'border-box',
  textDecoration: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  borderStyle: 'solid',
  borderWidth: '$button',
  cursor: 'pointer',
  borderRadius: '$button',
  width: 'var(--_b-button-width)',
  '&:focus-visible, &.focus-visible': {
    outline: '$utilityInteractionFocusOutline solid 3px',
    outlineOffset: '2px',
    borderRadius: '$button',
    borderWidth: '$focusOutline',
    '@bpSafari': {
      '@media': {
        outlineStyle: 'auto',
      },
    },
  },

  svg: {
    fill: 'currentColor',
    display: 'flex',
  },

  variants: {
    version: {
      primary: {
        backgroundColor: '$buttonPrimaryBg',
        color: '$buttonPrimaryFg',
        borderColor: '$buttonPrimaryBorder',
        '&:hover, &.hover': {
          backgroundColor: '$buttonPrimaryHoverBg',
          color: '$buttonPrimaryFg',
          borderColor: '$buttonPrimaryHoverBorder',
        },
        '&:active, &:active:hover, &.active': {
          backgroundColor: '$buttonPrimaryActiveBg',
          color: '$buttonPrimaryFg',
          borderColor: '$buttonPrimaryActiveBg',
        },
        '&:focus-visible, &.focus-visible': {
          backgroundColor: '$buttonPrimaryActiveBg',
          color: '$buttonPrimaryFg',
          borderColor: '$buttonPrimaryHoverBorder',
        },
      },
      secondary: {
        backgroundColor: '$buttonSecondaryBg',
        color: '$buttonSecondaryFg',
        borderColor: '$buttonSecondaryBorder',
        transition: '300ms',
        '&:hover, &.hover': {
          backgroundColor: '$buttonSecondaryHoverBg',
          color: '$buttonSecondaryFg',
          borderColor: '$buttonSecondaryHoverBorder',
        },
        '&:active, &:active:hover, &.active': {
          backgroundColor: '$buttonSecondaryActiveBg',
          color: '$buttonSecondaryFg',
          borderColor: '$buttonSecondaryHoverBorder',
        },
        '&:focus-visible, &.focus-visible': {
          backgroundColor: '$buttonSecondaryActiveBg',
          color: '$buttonSecondaryFg',
          borderColor: '$buttonSecondaryHoverBorder',
        },
      },
      outlined: {
        backgroundColor: '$buttonOutlinedBg',
        color: '$buttonOutlinedFg',
        borderColor: '$buttonOutlinedBorder',
        '&:hover, &.hover': {
          backgroundColor: '$buttonOutlinedHoverBg',
          color: '$buttonOutlinedFg',
          borderColor: '$buttonOutlinedHoverBorder',
        },
        '&:active, &:active:hover, &.active': {
          backgroundColor: '$buttonOutlinedActiveBg',
          color: '$buttonOutlinedFg',
          borderColor: '$buttonOutlinedHoverBorder',
        },
        '&:focus-visible, &.focus-visible': {
          backgroundColor: '$buttonOutlinedHoverBg',
          color: '$buttonOutlinedFg',
          borderColor: '$buttonOutlinedHoverBorder',
        },
      },
    },
    size: {
      medium: {
        minHeight: '2.75rem',
        padding: '$none 1rem',
        fontstyle: 'baseUtilityM',
      },
      small: {
        minHeight: '2.25rem',
        padding: '$none $x3',
        fontstyle: 'baseUtilityS',

        '& brick-icon-v2': {
          height: '26px',
          width: '26px',
        },
        '& svg': {
          height: 'inherit',
          width: 'inherit',
        },
      },
    },
    iconPlacement: {
      top: {
        display: 'flex',
        flexDirection: 'column',
      },
      left: {
        'brick-icon-v2 + span': {
          marginInlineStart: '8px',
        },
      },
      right: {
        '& brick-icon-v2': {
          order: 2,
          marginInlineStart: '8px',
        },
      },
    },
  },
  defaultVariants: {
    version: 'primary',
    size: 'medium',
    iconPlacement: 'left',
  },
});
